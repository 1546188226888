<template>
  <el-dialog
    :title="$t('components.dashboard_sidebar.social_links')"
    :visible.sync="isVisible"
  >
    <form @submit.prevent="updateUserSocials">
      <div class="columns is-multiline">
        <div class="column is-6">
          <DashboardSocialsModalInput
            v-model="form.facebook_profile"
            :v="$v.form.$each['facebook_profile']"
            name="facebook"
            label="Facebook"
          />
        </div>
        <div class="column is-6">
          <DashboardSocialsModalInput
            v-model="form.twitter_profile"
            :v="$v.form.$each['twitter_profile']"
            name="twitter"
            label="Twitter"
          />
        </div>
        <div class="column is-6">
          <DashboardSocialsModalInput
            v-model="form.instagram_profile"
            :v="$v.form.$each['instagram_profile']"
            name="instagram"
            label="Instagram"
          />
        </div>
        <div class="column is-6">
          <DashboardSocialsModalInput
            v-model="form.pinterest_profile"
            :v="$v.form.$each['pinterest_profile']"
            name="pinterest"
            label="Pinterest"
          />
        </div>
        <div class="column is-6">
          <DashboardSocialsModalInput
            v-model="form.linkedin_profile"
            :v="$v.form.$each['linkedin_profile']"
            name="linkedin"
            label="Linkedin"
          />
        </div>
      </div>
      <button type="submit" class="is-hidden" />
    </form>
    <div slot="footer" class="dialog-footer">
      <button class="button" @click="isVisible = false">
        Cancel
      </button>
      <button
        :class="{ 'is-loading':isUpdating }"
        class="button is-primary"
        @click="updateUserSocials"
      >
        Confirm
      </button>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardSocialsModalInput from './DashboardSocialsModalInput'
import { url } from 'vuelidate/lib/validators'

const fieldsToPrefill = [
  'facebook_profile',
  'instagram_profile',
  'linkedin_profile',
  'pinterest_profile',
  'twitter_profile'
]

function socialInputsFactory (from) {
  return fieldsToPrefill.reduce((all, field) => {
    all[field] = from ? from[field] : ''
    return all
  }, {})
}

export default {
  components: {
    DashboardSocialsModalInput
  },
  validations: {
    form: {
      $each: {
        url
      }
    }
  },
  data () {
    return {
      form: socialInputsFactory(),
      isUpdating: false
    }
  },
  computed: {
    ...mapGetters({
      dashboardSocialsModalVisible: 'dashboardSocialsModalVisible',
      user: 'dashboardUser'
    }),
    isVisible: {
      get () {
        return this.dashboardSocialsModalVisible
      },
      set (value) {
        this.toggleModal(value)
      }
    }
  },
  mounted () {
    this.form = socialInputsFactory(this.user.metadata)
  },
  methods: {
    ...mapActions({
      toggleModal: 'toggleDashboardSocialsModal',
      updateDashboardUserRequest: 'updateDashboardUserRequest'
    }),
    updateUserSocials () {
      this.isUpdating = true
      this.updateDashboardUserRequest({ metadata: this.form })
        .then(response => {
          this.isUpdating = false
          this.isVisible = false
          this.$notify.success(this.$t('success.successfully_updated_user_socials'))
        })
        .catch(error => {
          this.isUpdating = false
          this.$displayRequestError(error, this.$t('errors.generic'))
        })
    }
  }
}
</script>
