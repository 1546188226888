<template>
  <form-field
    :validator="v"
    :label="label"
    :left-icon="name"
  >
    <input
      v-model="input"
      :name="name"
      class="input"
      type="text"
      @input="v.$touch()"
      @blur="extractSocialHandle"
    >
  </form-field>
</template>

<script>
// import parseUrl from '@/shared/getHandleFromUrl'
// import { hasHttp } from '@/shared/utils'

export default {
  name: 'DashboardSocialsModalInput',
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    v: {
      type: Object,
      required: true
    }
  },
  computed: {
    input: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    extractSocialHandle (e) {
      // const value = e.target.value
      // if (hasHttp(value)) {
      //   this.input = parseUrl(value, this.name)
      // }
    }
  }
}
</script>
